import React, { Suspense } from "react"

import registry from "components/registry"

const resolveComponents = (entities = [], props) =>
  entities.reduce((result, entity) => {
    const resolved = resolveComponent(entity, props)
    resolved && result.push(resolved)
    return result
  }, [])

export const resolveComponent = (entity, { renderContext = "default", ...props } = {}) => {
  if (!entity) {
    return null
  }
  const Component = registry.getComponentForClass(entity.class, renderContext)
  return (
    Component &&
      <Suspense key={entity.key} fallback={<div>Loading...</div>}>
        <Component key={entity.key} entity={entity} {...props}/>
      </Suspense>) || null
}

export default resolveComponents
