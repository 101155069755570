import React from "react"
import PropTypes from "prop-types"

const PageLoader = ({ loading }) => {
  document.body.classList[loading ? "add" : "remove"]("loading")

  if (!loading) {
    return null
  }

  return (
    <div className="page-loader sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  )
}
PageLoader.propTypes = {
  loading: PropTypes.bool,
}

export default PageLoader
