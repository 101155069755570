const nameToArray = (name = []) => Array.isArray(name) ? name : name.toString().split(" ")

// in the code below:
//   - "things" can refer to an array of: entities or links or actions
//   -"name" can refer to className (class) or relationName
const filterByAbstract = (propName, extractFunc = Array.prototype.some) =>
  (things, name) => {
    name = nameToArray(name)
    return Array.prototype.filter.call((things || []), thing => extractFunc.call(name, (n => (thing[propName] || []).indexOf(n) > -1)))
  }


const findByAbstract = (propName, strict) =>
  (things, name) =>
    filterByAbstract(propName, strict)(things, name)
    .sort((a, b) => (a[propName] || []).length - (b[propName] || []).length)[0]

export const resolveLinks = (links = [], relations = []) => (
  relations.reduce((resolvedLinks, relation) => (
    resolvedLinks.concat(filterByRelation(links, relation))
  ), [])
)

export const findByRelation = findByAbstract("rel")
export const findByRelationStrict = findByAbstract("rel", Array.prototype.every)
export const filterByRelation = filterByAbstract("rel")
export const filterByRelationStrict = filterByAbstract("rel", Array.prototype.every)

export const findByClass = findByAbstract("class")
export const findByClassStrict = findByAbstract("class", Array.prototype.every)
export const filterByClass = filterByAbstract("class")
export const filterByClassStrict = filterByAbstract("class", Array.prototype.every)

export const findByType = findByAbstract("type")
export const filterByType = filterByAbstract("type")

export const isEmptyString = (value) => {
  const isString = (typeof value === "string" || value instanceof String)
  if (!isString) {
    return false
  }
  return value === ""
}
