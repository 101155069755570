// Utility function to get access nested object properties securely
//
// Usage:
//   get(account, "user.invoice_address.first_name")
// https://stackoverflow.com/a/23809123/1410020

/**
 * Gets a (deeply nested) value out of an object in a safe way.
 *
 * @param {Object} obj
 * @param {String} One or more keys to search for in the obj.
 *                 This can also be an array of strings.
 *
 * @return The value for the first found key (left most) or undefined
 */
const get = (obj, ...keys) => {
  if (typeof obj !== "object" || !keys) {
    return
  }
  keys = Array.isArray(keys[0]) ? keys[0] : keys
  for (let i = 0, len = keys.length; i < len; ++i) {
    const result = keys[i].split(".")
    .reduce((o, x) => (typeof o == "undefined" || o === null) ? o : o[x], obj)
    if (result !== undefined && result !== null) {
      return result
    }
  }
}
export default get
