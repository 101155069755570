import translations from "provision/translations.json"
import get from "get"
import { getItem } from "storage"
import { LANGUAGE_KEY } from "api/api"

// de-DE => de
// de => de
const getBaseLang = (lang) => {
  const parts = lang.split('-')
  return parts[0]
}
/**
 * Simple translate method for now.
 * Can be used like:
 *
 * translate("error.generic")
 * or
 * translate("error.showNumber", { total: 23 })
 *
 * The later will work as a string replace where `%{total}` will be replace by `23`
 * "There is at least %{total} errors" -> "There is at least 23 errors"
 */
export default (key, values) => {
  const lang = getItem(LANGUAGE_KEY) || translations.referenceLanguage
  let translated = get(translations[lang], key)

  const baseLang = getBaseLang(lang)
  if (!translated && baseLang && baseLang !== lang) {
    // if 'de-DE' has no translation check 'de'
    translated = get(translations[baseLang], key)
  }

  if (!values || !translated) {
    return translated || `${lang}:${translations.namespace}.${key}`
  }

  return Object
  .keys(values)
  .reduce(
    (result, valueKey) => result.replace(`%{${valueKey}}`, values[valueKey]),
    translated
  )
}
