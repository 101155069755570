import React from "react"
import PropTypes from "prop-types"

import get from "get"
import GenericError from "components/Error/GenericError"

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    render: PropTypes.func,
    id: PropTypes.string,
  }
  static defaultProps = {
    render: GenericError,
  }

  state = { error: undefined }

  componentDidCatch(error) {
    this.setState(() => ({ error }))
  }

  componentDidUpdate(prevProps) {
    // When the page id change, we remove the error.
    // useful for a back button pressed after an error occured
    if (prevProps.id !== this.props.id) {
      this.setState(() => ({ error: undefined }))
    }
  }

  render() {
    const { error } = this.state
    const { children, render } = this.props

    if (!error) {
      return children || null
    }

    const status = get(error, "response.status")
    return render({ error, status })
  }
}

export default ErrorBoundary
