import get from "get"
import { self, selfHref } from "api/links"
import { findByRelationStrict } from "api/utils"

const IS_DEV = process.env.NODE_ENV === "development"

const normalizeEntity = (entity = {}, requestUrl, parent) => {
  IS_DEV && checkEntity(entity, requestUrl)

  const safeEntity = {
    title: entity.title,
    class: entity.class || [],
    properties: entity.properties || {},
    actions: normalizeActions(entity.actions),
    entities: entity.entities || [],
    links: normalizeLinks(entity.links),
    rel: entity.rel || [],
    href: entity.href || "",
    key: entity.title,
    selfLink: "",
  }
  safeEntity.entities = normalizeEntities(safeEntity.entities, safeEntity)
  safeEntity.properties = normalizeProperties(safeEntity)
  safeEntity.title = normalizeTitle(safeEntity)
  safeEntity.selfLink = computeSelfLink(safeEntity.links, safeEntity.href)
  safeEntity.key = computeKey(safeEntity)

  if (parent) {
    safeEntity.parent = parent
  }

  return safeEntity
}

const normalizeTitle = (entity) => entity.title || entity.properties.title || entity.properties.name || (Array.isArray(entity.rel) && entity.rel.length && `rel: ${entity.rel.join(", ")}`) || ""

const isObject = (prop) => prop === Object(prop) && Object.prototype.toString.call(prop) !== "[object Array]"
const isLegacyCompoundProperty = (prop) => isObject(prop) && (("title" in prop) || ("value" in prop))

const getOrSetTranslationsEntity = (entity) => {
  let i18nEntity = findByRelationStrict(entity.entities, ["i18n"])
  if (!i18nEntity) {
    i18nEntity = normalizeEntity({ rel: ["i18n"] })
    entity.entities.push(i18nEntity)
  }
  return i18nEntity
}

const normalizeProperties = (entity) => {
  // Find the i18n rel sub-entity
  let i18nEntity
  const properties = entity.properties
  // first, normalize to ensure all properties looks like an object with title/value
  return Object
  .keys(properties)
  .reduce((result, key) => {
    const value = properties[key]
    if (isLegacyCompoundProperty(value)) {
      result[key] = value.value
      if (!i18nEntity) {
        i18nEntity = getOrSetTranslationsEntity(entity)
      }
      i18nEntity.properties[key] = value.title || key
    } else {
      result[key] = value
    }
    return result
  }, {})
}

const computeKey = ({ selfLink, title, class: classes = [], rel = [] }) => selfLink || title + classes.join(" ") + rel.join(" ")

const computeSelfLink = (links, href) => selfHref(links) || href

const normalizeActions = (actions = []) => (
  Object.values(
    actions
    .filter(action => action.name && action.href)
    .reduce((normalizedActions, action) => {
      normalizedActions[action.name] = action
      return normalizedActions
    }, {})
  )
  .map(action => {
    action.type = action.type || "application/x-www-form-urlencoded"
    return action
  })
)

export const normalizeEntities = (entities = [], parent) => {
  if (entities.length) {
    IS_DEV && console.groupCollapsed("----- sub-entities")
    entities = entities.map(e => normalizeEntity(e, undefined, parent))
    IS_DEV && console.groupEnd()
  }

  return entities
}

const normalizeLinks = (links = []) => links.filter((link = {}) => link.href && Array.isArray(link.rel) && link.rel.length)

// Only for dev purposes
export const checkEntity = (entity, requestUrl) => {
  const noTitle = !entity.title
  const noKey = !computeKey(entity)
  const links = entity.links || []
  const normalizedLinks = normalizeLinks(links)
  const noHrefOrSelfLink = !entity.href && !self(normalizedLinks)
  const hasInvalidLinks = links.length !== normalizedLinks.length

  if (noTitle || noKey || noHrefOrSelfLink || hasInvalidLinks) {
    console.groupCollapsed("entity")
    requestUrl && console.log(requestUrl)
    if (noTitle) {
      console.group("title")
      console.warn("`entity.title` not defined")
      if (!get(entity.properties, "title")) {
        console.warn("`entity.properties.title` not defined")
      }
      if (!get(entity.properties, "name")) {
        console.warn("`entity.properties.name` not defined")
      }
      console.groupEnd()
    }

    if (noKey) {
      console.warn("entity can't compute a key")
    }

    if (noHrefOrSelfLink || hasInvalidLinks) {
      console.group("links")
      if (noHrefOrSelfLink) {
        console.warn("`entity` has no `href` prop nor a `self` link")
      }
      if (hasInvalidLinks) {
        console.warn("`entity` contains some invalid links")
      }
      console.groupEnd()
    }

    console.log(entity)

    console.groupEnd()
  }
}

export default normalizeEntity
