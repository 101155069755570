import PropTypes from "prop-types"

import SirenEntityType from "api/SirenEntityType"
import { resolveComponent } from "components/resolveComponents"

const Entity = ({ entity, ...props }) => resolveComponent(entity, props)
Entity.propTypes = {
  entity: SirenEntityType,
  renderContext: PropTypes.string,
}

export default Entity
