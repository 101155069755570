import React from "react"

import Entity from "components/Entity"
import { filterByClass, filterByClassStrict, filterByRelation, filterByRelationStrict } from "api/utils"

const defaultCallback = entity => <Entity key={entity.key} entity={entity}/>

const mapEntitiesByClassAbstract = (strict) =>
  (entities = [], className, callback = defaultCallback) =>
    (strict ? filterByClassStrict : filterByClass)(entities, className)
    .map(callback)

const mapEntitiesByRelationAbstract = (strict) =>
  (entities = [], relation, callback = defaultCallback) =>
    (strict ? filterByRelationStrict : filterByRelation)(entities, relation)
    .map(callback)

export const mapEntitiesByClass = mapEntitiesByClassAbstract(false)
export const mapEntitiesByClassStrict = mapEntitiesByClassAbstract(true)

export const mapEntitiesByRelation = mapEntitiesByRelationAbstract(false)
export const mapEntitiesByRelationStrict = mapEntitiesByRelationAbstract(true)

export const findBySelfLink = (entities, selfLink) => entities.find(entity => entity.selfLink === selfLink)

export const findAndReplaceEmbeddedLink = (entities = [], resolvedEntity) => {
  const url = new URL(resolvedEntity.selfLink)
  let selfLink = url.href
  if (url.search && url.href.indexOf(url.search) !== -1) {
    selfLink = url.href.slice(0, url.href.indexOf(url.search))
  }

  return entities.map(entity => {
    if (entity.href !== selfLink) {
      return entity
    }

    if (!resolvedEntity.rel.length) {
      resolvedEntity.rel = entity.rel
    }

    return resolvedEntity
  })
}

export const distinct = (entities) => {
  const selfHrefObj = {}
  return Array.prototype.reduce.call(entities || [], (result, entity) => {
    const href = entity.selfLink
    if (href && !selfHrefObj[href]) {
      result.push(entity)
      selfHrefObj[href] = true
    }
    return result
  }, [])
}
