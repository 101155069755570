import { filterByRelation, findByRelation } from "api/utils"

const ACTION_ENTITY = "http://schema.org/Action"

export const ACTION_STATUS = {
  COMPLETED: "http://schema.org/CompletedActionStatus",
  FAILED: "http://schema.org/FailedActionStatus",
  POTENTIAL: "http://schema.org/PotentialActionStatus",
}
const ACTION_STATUS_KEYS = Object.keys(ACTION_STATUS)

export const ACTION = {
  CREATE: "http://schema.org/CreateAction",
  UPDATE: "http://schema.org/UpdateAction",
  DELETE: "http://schema.org/DeleteAction",
}
const ACTION_KEYS = Object.keys(ACTION)

export const getActionStatus = ({ entities = [] }) => {
  const entity = findByRelation(entities, "actionStatus")
  const id = (entity && ACTION_STATUS_KEYS.find(key => entity.class.find(klass => klass === ACTION_STATUS[key]))) || "FAILED"
  return ACTION_STATUS[id]
}

export const getAction = (entity = {}) => {
  const id = ACTION_KEYS.find(key => entity.class.find(klass => klass === ACTION[key]))
  return ACTION[id]
}

export const isActionSuccess = (entity = {}) => (
  getActionStatus(entity) === ACTION_STATUS.COMPLETED || ACTION_STATUS.POTENTIAL
)

export const isActionEntity = (entity = {}) => !!entity.class.find(klass => klass === ACTION_ENTITY)

export const actionTitle = (entity = {}) => {
  if (entity.title) {
    return entity.title
  }
  const status = findByRelation(entity.entities, "actionStatus")
  return (status && status.title !== "rel: actionStatus" ? status.title : status.properties.description) || null
}

export const getActionEntityStatus = (entity = {}) => findByRelation(entity.entities, "actionStatus")
export const getActionEntityItems = (entity = {}) => filterByRelation(entity.entities, "object")

const findAndPerform = action => (entities = [], nextEntities = []) => (
  entities.reduce((result, oldEntity) => {
    let actionPerformed = false
    nextEntities.forEach(newEntity => {
      if (oldEntity.selfLink === newEntity.selfLink) {
        if (action === ACTION.UPDATE) {
          result.push(newEntity)
        }
        actionPerformed = true
      }
    })
    if (!actionPerformed) {
      result.push(oldEntity)
    }
    return result
  }, [])
)

export const findAndReplace = findAndPerform(ACTION.UPDATE)
export const findAndDelete = findAndPerform(ACTION.DELETE)
