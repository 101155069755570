import api from "api/api"
import get from "get"
import { findByRelation, findByRelationStrict } from "api/utils"

export const self = (links = []) => findByRelationStrict(links, "self")
const safeSelf = (links = []) => self(links) || {}
export const selfHref = (links = []) => safeSelf(links).href

const noop = () => {}

// follow({ links: [{ rel: ["tada"], href: "..." }], relationName: "tada", callback: () => {} })
// follow({ link: "https://tada.com", callback: () => {} })
export const follow = ({ links = [], link = "", relationName = "", callback = noop, strict } = {}) => {
  link = link || ((strict ? findByRelationStrict : findByRelation)(links, relationName) || {}).href
  link && api({ href: link }).then(({ entity }) => callback(entity))
}

// followAndSetState({ links: [{ rel: ["tada"], href: "..." }], relationName: "tada", component: this, stateProp: "todos", property: "properties.list" })
// followAndSetState({ link: "https://tada.com", component: this, stateProp: "todos", "properties.list")
export const followAndSetState = ({ links, link, relationName, component = { setState: noop }, stateProp, key, strict } = {}) =>
  follow({
    links,
    link,
    relationName,
    callback: (entity) =>
      component.setState(() => ({ [stateProp]: key ? get(entity, key) : entity })),
    strict,
  })
