import PropTypes from "prop-types"

const StringArray = PropTypes.arrayOf(PropTypes.string)

const FieldType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  class: StringArray,
  type: PropTypes.string,
  // `value` can be PropTypes.string, PropTypes.number, PropTypes.bool,
  // PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.bool])})),
  // undefined // it's because of this undefined that we need to use `any`
  value: PropTypes.any,
  title: PropTypes.string,
})

export const ActionType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  class: StringArray,
  method: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  fields: PropTypes.arrayOf(FieldType),
})

export const LinkType = PropTypes.shape({
  rel: StringArray.isRequired,
  class: StringArray,
  href: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
})

export const LinksType = PropTypes.arrayOf(LinkType)
export const ActionsType = PropTypes.arrayOf(ActionType)

const EntityType = PropTypes.shape({
  class: PropTypes.arrayOf(PropTypes.string),
  properties: PropTypes.object,
  links: LinksType,
  actions: ActionsType,
  title: PropTypes.string,
})

const EmbeddedEntity = PropTypes.shape({
  ...EntityType,
  rel: StringArray.isRequired,
  href: PropTypes.string.isRequired,
})

export const EntitiesType = PropTypes.arrayOf(PropTypes.oneOfType([EntityType, EmbeddedEntity]))
EntityType.entities = EntitiesType
EmbeddedEntity.entities = EntitiesType

export default EntityType
