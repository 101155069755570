import React from "react"
import PropTypes from "prop-types"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

const onClick = () => {
  window.history.back()
}

const GenericError = ({ error, status }) => (
  <React.Fragment>
    <div className="generic-error">
      <div>
        <h2>{status}</h2>
        <h1>{getFrontendTranslations() ? getFrontendTranslations().error.message : translate("error.message")}</h1>
        <div>{error.toString()}</div>
        <h5><a href="#" onClick={onClick}>{getFrontendTranslations() ? getFrontendTranslations().error.goBack : translate("error.goBack")}</a></h5>
      </div>
    </div>
    <style jsx>{`
      .generic-error {
        background-color: black;
        color: white;
        text-align: center;
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
      }
    `}</style>
  </React.Fragment>
)

GenericError.propTypes = {
  error: PropTypes.object,
  status: PropTypes.string,
}

export default GenericError
